<template>
  <div>
    <b-tabs
      v-if="interview.interview_number"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="MessageSquareIcon" />
          <span>Görüşme Notu</span>
        </template>
        <component
          :is="interview.interview_type_component"
          v-if="interview.interview_type_component"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-view />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CustomerView from '@/views/Customers/CustomerView.vue'
import Sales from '@/views/Interviews/components/view/Sales.vue'
import ServiceMechanical from '@/views/Interviews/components/view/ServiceMechanical.vue'
import ServiceDamage from '@/views/Interviews/components/view/ServiceDamage.vue'
import SecondHand from '@/views/Interviews/components/view/SecondHand.vue'
import Insurance from '@/views/Interviews/components/view/Insurance.vue'
import Rental from '@/views/Interviews/components/view/Rental.vue'
import SpareParts from '@/views/Interviews/components/view/SpareParts.vue'
import CustomerRelationship from '@/views/Interviews/components/view/CustomerRelationship.vue'
import RealtySales from '@/views/Interviews/components/view/RealtySales.vue'
import AccessorySales from '@/views/Interviews/components/view/AccessorySales.vue'

export default {
  name: 'InterviewView',
  components: {
    BTabs,
    BTab,
    Sales,
    CustomerView,
    ServiceMechanical,
    ServiceDamage,
    SecondHand,
    Insurance,
    Rental,
    SpareParts,
    CustomerRelationship,
    RealtySales,
    AccessorySales,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    newLine() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  created() {
    this.$store.dispatch('interviews/interviewReset')
    this.getInterview()
  },
  methods: {
    setNewLine(data) {
      this.newLine.interview_number = data.interview_number
      this.newLine.idate = this.moment().format('YYYY-MM-DD')
      this.newLine.itime = this.moment().format('LT')
      this.newLine.id_com_customer = data.id_com_customer
      this.newLine.id_com_interview_type = data.id_com_interview_type
      this.newLine.id_com_interview_subject = data.id_com_interview_subject
      this.newLine.id_com_brand = data.id_com_brand
      this.newLine.id_com_model = data.id_com_model
      this.newLine.id_com_status = data.id_com_status
    },
    getInterview() {
      this.$store.dispatch('interviews/interviewView', this.$route.params.interview_number)
        .then(response => {
          if (response.id_com_customer) {
            this.getCustomer(response.id_com_customer)
            this.setNewLine(response)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
  },
}
</script>
